import { createSlice } from "@reduxjs/toolkit";

export const commonSlice = createSlice({
  name: "commonValues",
  initialState: {},
  reducers: {
    update: (state, action) => {
      return action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { update } = commonSlice.actions;

export default commonSlice.reducer;
