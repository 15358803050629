// @ts-nocheck
import React from "react";
import style from "./style.module.scss";

const PrimaryButton = ({ click, value }) => {
  return (
    <button onClick={click} className={`${style.button} ${style.primary}`}>
      {value}
    </button>
  );
};

export default PrimaryButton;
