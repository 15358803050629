import DangerButton from "../../../../components/Button/DangerButton";
import SuccessButton from "../../../../components/Button/SuccessButton";
import WarningButton from "../../../../components/Button/WarningButton";
import { formatCurrency } from "../../../../shared/utils/helper";
const { PartyTypes } = await import(
  `${process.env.REACT_APP_API_ENDPOINT}/common/constant.js`
);

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Category",
    dataIndex: "category",
    key: "category",
    render: (_: any, record: any) => {
      return record.PartyCategory.name;
    },
  },
  {
    title: "Mobile Number",
    dataIndex: "mobileNumber",
    key: "mobileNumber",
    align: 'center',
  },
  {
    title: "Party Type",
    dataIndex: "partyType",
    key: "partyType",
    align: 'center',
    render: (_: any, record: any) => {
      return PartyTypes[record.partyType];
    },
  },
  {
    title: "Balance",
    dataIndex: "balance",
    key: "balance",
    align: 'right',
    render: (_: any, record: any) => {
      return formatCurrency(record.openingBalance);
    },
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    align: 'right',
    render: (_: any, record: any) => {
      return (
        <span className="d-inline-flex col-gap-5">
          <SuccessButton
            value={<i className="fas fa-eye"></i>}
            click={() => {}}
          />
          <WarningButton
            value={<i className="fas fa-pencil"></i>}
            click={() => {}}
          />
          <DangerButton
            value={<i className="fas fa-trash"></i>}
            click={() => {}}
          />
        </span>
      );
    },
  },
];

export default columns;
