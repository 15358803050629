import { Outlet } from "react-router";
import Layout from "./components/Layout/Layout";
import PartyList from "./pages/PartyList/PartyList";

const routes: Array<any> = [
  {
    path: "/app",
    component: <Layout />,
    children: [
      {
        path: "party",
        component: <Outlet/>,
        children: [
          {
            path: "list",
            component: <PartyList/>,
          },
        ],
      },
    ],
  },
];

export default routes;
