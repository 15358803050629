// @ts-nocheck
import React, { useState } from "react";
import { Table } from "antd";
import style from "./style.module.scss";

const DataTable = ({ columns, data }) => {
  const records = data.map((record, index) => {
    return { ...record, key: index };
  });

  return (
    <Table className={style.dataTable} columns={columns} dataSource={records} />
  );
};

export default DataTable;
