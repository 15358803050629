// @ts-nocheck
import React from "react";
import style from "./style.module.scss";

const WarningButton = ({ click, value }) => {
  return (
    <button onClick={click} className={`${style.button} ${style.warning}`}>
      {value}
    </button>
  );
};

export default WarningButton;
