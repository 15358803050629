import React, { useEffect } from "react";
import "./App.scss";
import Router from "./Router";
import { useDispatch } from "react-redux";
import { update } from "./shared/redux/commonSlice";

const { PartyTypes } = await import(
  `${process.env.REACT_APP_API_ENDPOINT}/common/constant.js`
);

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      update({
        partyTypes: PartyTypes,
      })
    );
  }, [PartyTypes]);

  return <Router />;
}

export default App;
