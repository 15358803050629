import React from "react";
import DataTable from "../../../../components/DataTable/DataTable";
import useFetchPartyList from "../../../../shared/hooks/useFetchPartyList";
import columns from "./TableColumns";
import PrimaryButton from "../../../../components/Button/PrimaryButton";

const PartyList = () => {
  const { parties } = useFetchPartyList();

  return (
    <div>
      <div className="d-flex justify-content-end">
        <PrimaryButton
          value={
            <>
              <i className="fas fa-add"></i> Add Party
            </>
          }
          click={() => {}}
        />
      </div>
      <div className="margin-top-15">
        <DataTable columns={columns} data={parties} />
      </div>
    </div>
  );
};

export default PartyList;
