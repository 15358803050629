import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router";
import userRoutes from "./modules/user/router";
import homeRoutes from "./modules/home/router";

// Recursive function to render routes and nested routes
const renderRoutes = (routes: Array<any>) => {
  return routes.map((route, index) => {
    if (route.children) {
      // If there are nested routes, render them recursively inside Routes
      return (
        <Route key={index} path={route.path} element={route.component}>
          {renderRoutes(route.children)}
        </Route>
      );
    }

    // Render the route if there are no nested routes
    return <Route key={index} path={route.path} element={route.component} />;
  });
};

function Router() {
  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>{renderRoutes(userRoutes)}</Routes>
        <Routes>{renderRoutes(homeRoutes)}</Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default Router;
