import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import { Outlet } from "react-router";
import style from "./style.module.scss"

const Layout = () => {
  return (
    <>
      <div className={style.layout}>
        <Sidebar />
        <div className={style.main}>
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Layout;
