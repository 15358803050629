export const cleanObject = (obj: Object) => {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([_, value]) => value !== undefined && value !== null && value !== ""
    )
  );
};

export const formatCurrency = (
  value: number,
  locale = "en-US",
  currency = "INR"
) => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(value);
};
