// @ts-nocheck
import React from "react";
import style from "./style.module.scss";

const DangerButton = ({ click, value }) => {
  return (
    <button onClick={click} className={`${style.button} ${style.danger}`}>
      {value}
    </button>
  );
};

export default DangerButton;
