import axios from "axios";
import { cleanObject } from "../utils/helper";

export const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const webService = {
  get: async (route: string, params = undefined) => {
    return webService.execute("GET", route, undefined, params);
  },

  post: async (route: string, data = undefined, params = undefined) => {
    return webService.execute("POST", route, data, params);
  },

  put: async (route: string, data = undefined, params = undefined) => {
    return webService.execute("PUT", route, data, params);
  },

  patch: async (route: string, data = undefined, params = undefined) => {
    return webService.execute("PATCH", route, data, params);
  },

  destroy: async (route: string, data = undefined, params = undefined) => {
    return webService.execute("DELETE", route, data, params);
  },

  execute: async (
    method: string,
    url: string,
    data = undefined,
    params = undefined
  ) => {
    const accessToken = null;

    // Configure the request
    const config = {
      method,
      url: `${REACT_APP_API_ENDPOINT}/api/${url}`,
      headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {},
      params: params ? cleanObject(params) : undefined,
      data: data ? cleanObject(data) : undefined,
    };

    // store.dispatch(isExecuting(true));

    return axios(config)
      .then((response) => response.data) // Extract data from response
      .catch((error) => {
        console.error("API call error:", error); // Improved error logging
        if (error?.response?.data?.message) {
          // message.error(error?.response?.data?.message);
        }
        if (
          error?.response?.data?.errors &&
          error?.response?.data?.errors?.length > 0
        ) {
          for (const errorMessage of error?.response?.data?.errors) {
            // message.error(errorMessage);
          }
        }
        return Promise.reject(error); // Pass the error to the caller
      })
      .finally(() => {
        // store.dispatch(isExecuting(false));
      });
  },
};
