import React, { useState } from "react";
import style from "./style.module.scss";
import { NavLink } from "react-router";

const Sidebar = () => {
  const menu: Array<any> = [
    {
      label: "Dashboard",
      icon: "fa-home",
      color: "#a2c6ff",
      link: "/app/dashboard",
    },
    {
      label: "Parties",
      icon: "fa-users",
      color: "#ffc107",
      link: "/app/party/list",
      activeConditions: [
        "/app/party/list",
        "/app/party/add",
        "/app/party/view",
      ],
    },
    {
      label: "Items",
      icon: "fa-layer-group",
      color: "#07ff55",
      children: [
        {
          label: "Inventory",
          icon: "fa-cart-flatbed",
          link: "/app/items/list",
          activeConditions: ["/app/items/list"],
        },
      ],
    },
    {
      label: "Sales",
      icon: "fa-tags",
      color: "#fd07ff",
      children: [
        {
          label: "Sales Invoices",
          icon: "fa-file-invoice",
          link: "/app/sales/invoice/list",
          activeConditions: [
            "/app/sales/invoice/list",
            "/app/sales/invoice/create",
          ],
        },
        {
          label: "Quotation/Estimate",
          icon: "fa-file-invoice",
          link: "/app/sales/quotation/list",
          activeConditions: [
            "/app/sales/quotation/list",
            "/app/sales/quotation/create",
          ],
        },
        { label: "Payment In", icon: "fa-file-invoice", link: "#" },
        { label: "Sales Return", icon: "fa-file-invoice", link: "#" },
        { label: "Credit Note", icon: "fa-file-invoice", link: "#" },
        { label: "Delivery Challan", icon: "fa-file-invoice", link: "#" },
        { label: "Proforma Invoice", icon: "fa-file-invoice", link: "#" },
      ],
    },
    {
      label: "Purchases",
      icon: "fa-credit-card",
      color: "#07fff6",
      children: [
        { label: "Purchase Invoices", icon: "fa-file-invoice", link: "#" },
        { label: "Payment Out", icon: "fa-file-invoice", link: "#" },
        { label: "Purchase Return", icon: "fa-file-invoice", link: "#" },
        { label: "Debit Note", icon: "fa-file-invoice", link: "#" },
        { label: "Purchase Order", icon: "fa-file-invoice", link: "#" },
      ],
    },
    {
      label: "Reports",
      icon: "fa-file",
      color: "#d7ff07",
      link: "#",
    },
    {
      label: "Accounting",
      icon: "fa-file-invoice-dollar",
      color: "#ff9307",
      children: [
        {
          label: "Cash & Bank",
          icon: "fa-building-columns",
          link: "/app/accounting/cash-bank",
        },
        {
          label: "Expenses",
          icon: "fa-circle-dollar-to-slot",
          link: "/app/accounting/expense/list",
        },
      ],
    },
    {
      label: "Organisation",
      icon: "fa-building",
      color: "#ff9307",
      children: [
        {
          label: "Location",
          icon: "fa-map-location-dot",
          link: "/app/location/list",
        },
      ],
    },
  ];

  const [activeMenu, setActiveMenu] = useState(null);

  const handleToggleSubMenu = (index: any) => {
    setActiveMenu(activeMenu === index ? null : index);
  };

  return (
    <div className={style.sidebar}>
      <div className={style.header}>
        <div className="logo">
          {/* <img src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/facebook-app-round-white-icon.png"
                alt="No Logo Available"> */}
        </div>
        <div className="name">
          <div className="title">Mannar Lifestyle</div>
          <div className="phone">8956235689</div>
        </div>
      </div>
      <div className={style.content}>
        <ul className={style.menu}>
          {menu.map((item, index) => (
            <li
              key={index}
              className={`${item.children ? style.dropdown : ""} ${
                activeMenu === index ? style.active : ""
              }`}
              onClick={() => item.children && handleToggleSubMenu(index)}
            >
              <NavLink
                to={item.link || "#"}
                className={`${item.children ? style.dropdownButton : ""} ${
                  item.isActive ? style.active : ""
                }`}
              >
                <span>
                  <i
                    className={style.icon + ` fa-solid ${item.icon}`}
                    style={{ color: item.color }}
                  ></i>
                  <span>{item.label}</span>
                </span>
                {item.children && (
                  <i className={style.ddIcon + " fa-solid fa-angle-right"}></i>
                )}
              </NavLink>
              {item.children && (
                <ul className={style.dropdownContent}>
                  {item.children.map((subItem: any, subIndex: any) => (
                    <li key={subIndex} className={style.dropdownItem}>
                      <NavLink
                        to={subItem.link}
                        className={`${subItem.isActive ? style.active : ""}`}
                      >
                        <i className={style.icon + ` fa-solid ${subItem.icon}`}></i>
                        <span>{subItem.label}</span>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className={style.footer}>
        <span>
          <i className="fa-solid fa-chevron-right"></i>
        </span>
      </div>
    </div>
  );
};

export default Sidebar;
