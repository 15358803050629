const routes: Array<any> = [
  {
    path: "/",
    component: <>Home</>,
  },
  {
    path: "/unauthorized",
    component: <>Unauthorized</>,
  },
];

export default routes;
