import React, { useEffect, useState } from "react";
import { webService } from "../services/web.service";

const useFetchPartyList = () => {
  const [parties, setParties] = useState([]);

  const fetch = () => {
    webService.get("party/list").then((result: any) => {
      setParties(result.data);
    });
  };

  useEffect(() => {
    fetch();
  }, []);

  return { parties };
};

export default useFetchPartyList;
